import PlaceholderReact from 'components/atomic/PlaceholderReact'
import Breadcrumbs from '../atomic/Breadcrumbs'

const Default = ({ page, newPlaceholder, tabs, content }) => {
  return (
    <main className="cms-page cms-page--default">
      <div className="w-container">
        <Breadcrumbs breadcrumbs={page.breadcrumbs} />
        <PlaceholderReact
          contentTypeId={page.model}
          objectId={page.id}
          pkey="content"
          serverSideContent={newPlaceholder}
        />
      </div>

      {page.tabs.length ? (
        <section className="formazione-page__tabs ">
          <div className="w-container-inner">
            <div className="row">
              <aside className="col col-md-3 formazione-page__navigation">{tabs}</aside>
              <section className="col col-md-9 formazione-page__content">{content}</section>
            </div>
          </div>
        </section>
      ) : null}
    </main>
  )
}

export default Default
