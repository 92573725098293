import { PROGETTI_SPECIALI, api, IMMAGINE_HEADER, EXCLUDED_PAGES } from 'utils'
import { PAGINEDETAIL, PAGINE, REDIRECTS } from 'utils/endpoint'
import React, { useEffect, useState } from 'react'

import Default from '../components/template/Default'
import Error from 'next/error'
import Icon from '../components/atomic/Icon'
import MetaSeo from '../components/atomic/MetaSeo'
import PlaceholderReact from 'components/atomic/PlaceholderReact'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { api as pagebuilderApi } from 'react-pagebuilder/services/api'

const GenericPageTab = dynamic(() => import('../components/atomic/GenericPageTab'))
const ProgettiSpeciali = dynamic(() => import('../components/template/ProgettiSpeciali'))
const ImmagineHeader = dynamic(() => import('../components/template/ImmagineHeader'))

const Page = ({ page, newPlaceholder, newDefaultContent, statusCode }) => {
  if (!page) return <Error statusCode={statusCode} />

  const router = useRouter()

  const [tabs, setTabs] = useState([])
  const [current, setCurrent] = useState(null)
  const [content, setContent] = useState([])

  useEffect(() => {
    initializeTabs().then(showCurrentTab())
  }, [router.asPath])

  useEffect(() => {
    if (current) initializeTabs().then(initializeContent())
  }, [current])

  const defaultTab = page.tabs[0]

  const initializeContent = async () => {
    let content = newDefaultContent

    if (content) {
      content = page.tabs.map((tab) => (
        <div className={'tab-accordion' + (tab.id == current.id ? ' tab-accordion--opened' : '')}>
          <div className="tab-accordion__line" onClick={() => showCurrentTab(tab)}>
            <h3 className="tab-accordion__title">{tab.titolo}</h3>
            <span className="tab-accordion__icon">
              <Icon id="chevron-down" />
            </span>
          </div>
          <div className="tab-accordion__inner">
            <GenericPageTab tab={tab} key={tab.id} selected={tab.id == current.id}>
              {tab.id == current.id ? (
                <PlaceholderReact
                  contentTypeId={page.model}
                  objectId={page.id}
                  pkey={`${page.id}-${current.slug}-content`}
                  serverSideContent={current !== defaultTab ? null : newDefaultContent}
                />
              ) : null}
            </GenericPageTab>
          </div>
        </div>
      ))
      setContent(content)
    }
  }

  const initializeTabs = async () => {
    const tabs = page.tabs.map((tab) => (
      <a key={tab.id} href={'#' + tab.slug} onClick={() => showCurrentTab(tab)}>
        <h3
          className={`formazione-page__navigation__item ${tab.id === current?.id ? 'active' : ''}`}
        >
          {tab.titolo}
        </h3>
      </a>
    ))

    setTabs(tabs)
  }

  const selezionaTab = () => {
    const anchor = router.asPath.split('#')[1]

    if (!anchor) return defaultTab

    return page.tabs.find((tab) => tab.slug === anchor) || defaultTab
  }

  const showCurrentTab = (tab) => {
    if (!tab) {
      tab = selezionaTab()
    }
    setCurrent(tab)
  }

  const renderTemplate = (page) => {
    switch (page.template) {
      case PROGETTI_SPECIALI:
        return <ProgettiSpeciali page={page} />
      case IMMAGINE_HEADER:
        return (
          <ImmagineHeader
            page={page}
            newPlaceholder={newPlaceholder}
            tabs={tabs}
            content={content}
          />
        )
      default:
        return <Default page={page} newPlaceholder={newPlaceholder} tabs={tabs} content={content} />
    }
  }

  return (
    <>
      <MetaSeo seo={page.seo} />
      {renderTemplate(page)}
    </>
  )
}

export async function getStaticPaths() {
  const response = await api.get(PAGINE)
  const pagine = response.data

  const res = await api.get(REDIRECTS)
  const redirects = await res.data
  const sources = new Set(redirects.map((x) => x.old_path))

  const paths = pagine
    .filter((p) => p.tipologia === 'page')
    .filter((p) => !EXCLUDED_PAGES.includes(p.path))
    .filter((p) => !sources.has(p.path))
    .map((p) => ({
      params: { path: p.path.split('/').slice(1) },
    }))

  return {
    paths,
    fallback: 'blocking',
  }
}
export const getStaticProps = async ({ params: { path }, preview, previewData }) => {
  const route = '/' + path.join('/')

  const headers = preview && previewData?.headers ? { cookie: previewData.headers } : {}

  const response = await api.get(REDIRECTS, { params: { old_path: route } })
  const redirects = await response.data

  if (redirects.length) {
    return {
      redirect: {
        destination: redirects[0].new_path,
        permanent: false, // statusCode: 301
      },
    }
  }

  const params = {
    path: route,
    preview,
  }

  const result = await api.get(PAGINEDETAIL, { params, headers })
  let page = await result.data

  if (!page) {
    return {
      notFound: true,
    }
  }

  let newPlaceholder = null
  let newDefaultContent = null

  if (page.template !== PROGETTI_SPECIALI) {
    newPlaceholder = await pagebuilderApi.getPagebuilder({
      contentTypeId: page.model,
      objectId: page.id,
      key: 'content',
      edit_mode: false,
    })

    if (page.tabs.length) {
      newDefaultContent = await pagebuilderApi.getPagebuilder({
        contentTypeId: page.model,
        objectId: page.id,
        key: page.id + '-' + page.tabs[0].slug + '-content',
        edit_mode: false,
      })
    }
  }

  const toolbarProps = { toolbarPage: page, toolbarItem: page }

  if (!page.chiave) {
    page = { ...page, chiave: page.path } // per far funzionare la ricerca
  }

  return {
    props: {
      page,
      newPlaceholder,
      newDefaultContent,
      ...toolbarProps,
      key: page.id,
      revalidate: 60 * 2, // trovare alternativa in stile data-fetching-wrapper di Luca D. per altre pagine senza dover passare manualmente il revalidate
    },
    revalidate: 60 * 2,
  }
}

export default Page
