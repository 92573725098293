import React, { useEffect, useState } from 'react'
import { useUtente } from 'hooks'
import { PageBuilder } from 'react-pagebuilder/pagebuilder/PageBuilder'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'

const PlaceholderReact = ({ contentTypeId, objectId, pkey, serverSideContent }) => {
  const { utente, fetching: loading } = useSelector(state => state.utente)
  const { edit: toolbarEdit } = useSelector(state => state.toolbar)
  const [content, setContent] = useState()

  const router = useRouter()

  useEffect(() => {
    let content = null
    const edit = router.asPath.includes('?anteprima') ? false : toolbarEdit
    if (utente && edit) {
      content = (
        <div className="pb-content active">
          <iframe
            className="pagebuilder-placeholder"
            src={`/pagebuilder/?contentTypeId=${contentTypeId}&objectId=${objectId}&key=${pkey}`}
          />
        </div>
      )
    } else {
      content = (
        <div>
          <PageBuilder
            contentTypeId={contentTypeId}
            objectId={objectId}
            pkey={pkey}
            serverSideContent={serverSideContent}
          />
        </div>
      )
    }
    setContent(content)
  }, [utente, toolbarEdit])

  return <>{content && content}</>
}

export default PlaceholderReact
